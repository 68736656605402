import React from 'react'

const Logo = () => (
  <svg viewBox="-50 700 500 550">
    <title>Lantern of the Soul</title>
    <g
     transform="scale(4.2,4.2)">
      <g
        transform="matrix(2.0171019,0,0,2.0171019,2.2725049,167.47344)">
        <path
          d="M 42.6,56.5 V 30 c 0,0 0,0 0,0 0,-0.1 0,-0.2 0,-0.2 0,-0.1 0,-0.1 -0.1,-0.2 0,-0.1 -0.1,-0.1 -0.1,-0.2 0,-0.1 -0.1,-0.1 -0.1,-0.2 0,0 0,0 0,0 l -4.6,-3.8 1.5,-2.7 h 2.4 c 0.6,0 1.1,-0.5 1.1,-1.1 V 19 c 1.2,-1.9 1.8,-4.2 1.8,-6.5 C 44.4,5.6 38.8,0 32,0 25.2,0 19.5,5.6 19.5,12.4 c 0,2.3 0.6,4.6 1.8,6.5 v 2.6 c 0,0.3 0.1,0.6 0.3,0.8 0.2,0.2 0.5,0.3 0.8,0.3 h 2.4 l 1.5,2.7 -4.6,3.8 c 0,0 0,0 0,0 -0.1,0.1 -0.1,0.1 -0.1,0.2 0,0.1 -0.1,0.1 -0.1,0.2 0,0.1 0,0.1 -0.1,0.2 0,0.1 0,0.2 0,0.2 0,0 0,0 0,0 v 26.5 c -2.9,0.7 -5,3.3 -5,6.4 0,0.6 0.5,1.1 1.1,1.1 h 29 c 0.6,0 1.1,-0.5 1.1,-1.1 0,-3 -2.1,-5.6 -5,-6.3 z m -8.4,-0.2 h -4.4 v -3.8 h 4.4 z m 0,-6 h -4.4 c -1.2,0 -2.2,1 -2.2,2.2 v 3.8 h -4 V 31.1 h 16.8 v 25.2 h -4 v -3.8 c 0,-1.2 -1,-2.2 -2.2,-2.2 z m 1.4,-25.8 h -7.3 l -1.6,-3 0.9,-2.3 c 0.5,-1.2 1.7,-2.1 3.1,-2.1 h 2.6 c 1.3,0 2.6,0.8 3.1,2.1 l 0.9,2.3 z m -7.5,2.2 h 7.7 l 2.6,2.2 h -13 z M 23.4,18 c -1.1,-1.7 -1.7,-3.6 -1.7,-5.6 0,-5.6 4.6,-10.2 10.2,-10.2 5.6,0 10.2,4.6 10.2,10.2 0,2 -0.6,3.9 -1.6,5.5 -0.1,0.2 -0.2,0.4 -0.2,0.6 v 1.8 h -1.2 l -0.8,-2 c -0.8,-2.1 -2.9,-3.5 -5.1,-3.5 h -2.6 c -2.2,0 -4.3,1.4 -5.1,3.5 l -0.8,2 h -1.2 v -1.8 c 0.1,-0.1 0,-0.3 -0.1,-0.5 z m -4.7,43.8 c 0.5,-1.9 2.2,-3.3 4.3,-3.3 h 5.7 6.6 5.7 c 2.1,0 3.8,1.4 4.3,3.3 z"
        />
        <path
          d="m 52.1,41.4 h -5.6 c -0.6,0 -1.1,0.5 -1.1,1.1 0,0.6 0.5,1.1 1.1,1.1 h 5.6 c 0.6,0 1.1,-0.5 1.1,-1.1 0,-0.6 -0.5,-1.1 -1.1,-1.1 z"
        />
        <path
          d="m 45.6,38.7 c 0.1,0 0.3,0 0.4,-0.1 l 5.3,-1.9 c 0.6,-0.2 0.9,-0.8 0.7,-1.4 -0.2,-0.6 -0.8,-0.9 -1.4,-0.7 l -5.3,1.9 c -0.6,0.2 -0.9,0.8 -0.7,1.4 0.2,0.5 0.6,0.8 1,0.8 z"
        />
        <path
          d="m 51.3,48.4 -5.3,-2 c -0.6,-0.2 -1.2,0.1 -1.4,0.7 -0.2,0.6 0.1,1.2 0.7,1.4 l 5.3,1.9 c 0.1,0 0.3,0.1 0.4,0.1 0.5,0 0.9,-0.3 1,-0.7 0.2,-0.6 -0.1,-1.2 -0.7,-1.4 z"
        />
        <path
          d="m 18.6,42.5 c 0,-0.6 -0.5,-1.1 -1.1,-1.1 h -5.6 c -0.6,0 -1.1,0.5 -1.1,1.1 0,0.6 0.5,1.1 1.1,1.1 h 5.6 c 0.6,0 1.1,-0.5 1.1,-1.1 z"
        />
        <path
          d="m 12.7,36.7 5.3,1.9 c 0.1,0 0.3,0.1 0.4,0.1 0.5,0 0.9,-0.3 1,-0.7 0.2,-0.6 -0.1,-1.2 -0.7,-1.4 l -5.3,-1.9 c -0.6,-0.2 -1.2,0.1 -1.4,0.7 -0.2,0.4 0.1,1.1 0.7,1.3 z"
        />
        <path
          d="M 19.4,47.1 C 19.2,46.5 18.6,46.2 18,46.4 l -5.3,1.9 c -0.6,0.2 -0.9,0.8 -0.7,1.4 0.2,0.4 0.6,0.7 1,0.7 0.1,0 0.3,0 0.4,-0.1 l 5.3,-1.9 c 0.6,-0.1 0.9,-0.7 0.7,-1.3 z"
        />
      </g>
      <g
        transform="matrix(0.53373097,0,0,0.53373097,49.96659,235.98688)">
        <path
          d="M 24.2,39.334 11.422,18.126 C 8.741,22.09 7.174,26.865 7.174,32 c 0,2.552 0.388,5.015 1.107,7.334 z"
        />
        <path
          d="M 29.569,21.474 H 54.477 C 51.278,14.67 45.08,9.549 37.595,7.818 Z"
        />
        <path
          d="M 21.573,29.16 34.424,7.294 C 33.626,7.216 32.818,7.174 32,7.174 c -7.402,0 -14.053,3.26 -18.605,8.415 z"
        />
        <path
          d="M 26.75,56.045 34.612,42.334 H 9.436 c 3.302,7.181 9.929,12.524 17.9,14.047 z"
        />
        <path
          d="M 39.762,24.474 52.311,46.25 c 2.841,-4.037 4.516,-8.95 4.516,-14.25 0,-2.623 -0.412,-5.151 -1.17,-7.526 z"
        />
        <path
          d="M 42.32,34.923 29.819,56.725 c 0.719,0.063 1.446,0.101 2.181,0.101 7.234,0 13.753,-3.111 18.295,-8.064 z"
        />
      </g>
    </g>
  </svg>
)

export default Logo
